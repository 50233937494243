<template>
  <div>
       
       <div class="swirel1" v-if='type == 1'>
            <img class='swimg3-1' src="@/assets/images/arrow-right.png" alt="">
            <img class='swimg4-1' src="@/assets/images/arrow-left.png" alt="">
        </div>
        <div class="swirel1" v-if='type == 2'>
            <img class='swimg3-2' src="@/assets/images/arrow-left.png" alt="">
            <img class='swimg4-2' src="@/assets/images/arrow-left.png" alt="">
        </div>
        <div class="swirel1" v-if='type == 3'>
            <img class='swimg3-3' src="@/assets/images/arrow-right.png" alt="">
            <img class='swimg4-3' src="@/assets/images/arrow-left.png" alt="">
        </div>
       <div class="swirel1" v-if='type == 4'>
            <img class='swimg3-4' src="@/assets/images/arrow-right.png" alt="">
            <img class='swimg4-4' src="@/assets/images/arrow-left.png" alt="">
        </div>
        <div class="swirel1" v-if='type == 5'>
            <img class='swimg3-5' src="@/assets/images/arrow-right.png" alt="">
            <img class='swimg4-5' src="@/assets/images/arrow-left.png" alt="">
        </div>
        <div class="swirel1" v-if='type == 6'>
            <img class='swimg3-6' src="@/assets/images/arrow-right.png" alt="">
            <img class='swimg4-6' src="@/assets/images/arrow-left.png" alt="">
        </div>
        <div class="swirel1" v-if='type == 7'>
            <img class='swimg4-7' src="@/assets/images/arrow-right.png" alt="">
            <img class='swimg3-7' src="@/assets/images/arrow-left.png" alt="">
        </div>

        <div class="swirel1" v-if='type == 8'>
            <img class='swimg4-8' src="@/assets/images/arrow-left.png" alt="">
            <img class='swimg3-8' src="@/assets/images/arrow-left.png" alt="">
        </div>
        <div class="swirel1" v-if='type == 9'>
            <img class='swimg3-9' src="@/assets/images/arrow-right.png" alt="">
            <img class='swimg4-9' src="@/assets/images/arrow-right.png" alt="">
        </div>
        <div class="swirel2 d-none d-lg-block" v-if='type == 10'>
            <img class='swimg1-10' src="@/assets/images/arrow-right.png" alt="">
            <img class='swimg2-10' src="@/assets/images/arrow-right.png" alt="">

            <img class='swimg3-10' src="@/assets/images/arrow-left.png" alt="">
            <img class='swimg4-10' src="@/assets/images/arrow-left.png" alt="">
        </div>
        <div class="swirel3 d-block d-lg-none" v-if='type == 10'>
            <img class='swimg1-10' src="@/assets/images/arrow-right.png" alt="">
            <img class='swimg2-10' src="@/assets/images/arrow-right.png" alt="">

            <img class='swimg3-10' src="@/assets/images/arrow-left.png" alt="">
            <img class='swimg4-10' src="@/assets/images/arrow-left.png" alt="">
        </div>
  </div>
</template>

<script>
export default {
props:['type']
}
</script>

<style scoped>
.swirel3{
position:relative;
height: 600px;
}
.swirel2{
    z-index: 100000;
    height: 100px;
    width: 100px;
  }
.swirel1{
    z-index: 100000;
    height: 100px;
    width: 100px;
  }
 
 .swirel1 .swimg3-4{
      width: 50px;
    position:absolute;
    right: 20px;
    bottom: 20px;
  }
  .swirel1 .swimg4-4{
      width: 50px;
    position:absolute;
    right: 20px;
    bottom: 20px;
  }
  .swirel1 .swimg3-1{
    width: 50px;
    position:absolute;
    right: 40px;
    bottom: 20px;
  }
  .swirel1 .swimg4-1{
      width: 50px;
    position:absolute;
    right: 20px;
    bottom: 20px;
  }
   .swirel1 .swimg3-2{
    width: 50px;
    position:absolute;
    right: 20px;
    bottom: 40px;
  }
  .swirel1 .swimg4-2{
      width: 50px;
    position:absolute;
    right: 20px;
    bottom: 20px;
  } 

    .swirel1 .swimg3-3{
    width: 50px;
    position:absolute;
    right: 25px;
    bottom: 35px;
  }
  .swirel1 .swimg4-3{
      width: 50px;
    position:absolute;
    right: 20px;
    bottom: 20px;
  } 
     .swirel1 .swimg3-5{
    width: 50px;
    position:absolute;
    right: 25px;
    bottom: 20px;
  }
  .swirel1 .swimg4-5{
      width: 50px;
    position:absolute;
    right: 40px;
    bottom: 20px;
  }  
       .swirel1 .swimg3-6{
    width: 50px;
    position:absolute;
    right: 20px;
    bottom: 20px;
  }
  .swirel1 .swimg4-6{
      width: 50px;
    position:absolute;
    right: 20px;
    bottom: 48px;
  }  
  .swirel1 .swimg3-7{
    width: 50px;
    position:absolute;
    right: 20px;
    bottom: 20px;
  }
  .swirel1 .swimg4-7{
      width: 50px;
    position:absolute;
    right: 20px;
    bottom: 48px;
  }
  .swirel1 .swimg3-8{
    width: 50px;
    position:absolute;
    right: 20px;
    bottom: 20px;
  }
  .swirel1 .swimg4-8{
      width: 50px;
    position:absolute;
    right: 40px;
    bottom: 20px;
  }
   .swirel1 .swimg3-9{
    width: 50px;
    position:absolute;
    right: 20px;
    bottom: 30px;
  }
  .swirel1 .swimg4-9{
      width: 50px;
    position:absolute;
    right: 30px;
    bottom: 20px;
  }
  



     .swirel2 .swimg1-10{
    height: 260px;
    width: 220px;
    position:absolute;
    bottom: -150px;
    right: -105px;
  }
  .swirel2 .swimg2-10{
      height: 260px;
      width: 220px;
    position:absolute;
    bottom: 0px;
    right: -105px;
  }
       .swirel2 .swimg3-10{
    height: 260px;
    width: 220px;
    position:absolute;
    bottom: 150px;
    right: -105px;
  }
  .swirel2 .swimg4-10{
      height: 260px;
      width: 220px;
    position:absolute;
    bottom: 300px;
    right: -105px;
  }
  
  
  
  .swirel3 .swimg1-10{
    height: 300px;
    width: 250px;
    position:absolute;
    bottom: -100px;
    right: 10px;
  }
  .swirel3 .swimg2-10{
      height: 300px;
      width: 250px;
    position:absolute;
    bottom: 50px;
    right: 10px;
  }
       .swirel3 .swimg3-10{
    height: 300px;
    width: 250px;
    position:absolute;
    bottom: 200px;
    right: 10px;
  }
  .swirel3 .swimg4-10{
      height: 300px;
      width: 250px;
    position:absolute;
    bottom: 350px;
    right: 10px;
  }
</style>
<template>
  <div>
    <button class="px-md-4 btn btn-primary float-right position-relative"
    style="z-index:99;"
    @click="$root.Brender = true"> READ / DOWNLOAD HERE </button>
    
  </div>
</template>

<script>

export default {
    components: {
  },
  
    data(){
        return {
            ht:0,
        }
    },
    methods:{
        
    }
}
</script>

<style>

</style>
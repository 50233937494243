<template>
  <div>
      <nav-bar :wallet='false'></nav-bar>
      

      <wp-title-section></wp-title-section>
    <footer-section></footer-section>

  </div>
</template>

<script>
import WpTitleSection from '@/components/WpTitleSection.vue'

export default {
   components:{
    NavBar:() => import('@/components/NavBar.vue'),FooterSection:() => import('@/components/FooterSection'),

   WpTitleSection
   },
    name: "WhitePaper",
}
</script>

<style>

</style>
<template>
  <div style="height:100%;">
      <div class="clLight wpCard position-relative" style="height:100%;">
          <h1 class="card_title d-none d-md-block"> {{item.title}} </h1>
          <h1 class="card_title d-md-none" style="font-size:112%;"> {{item.title}} </h1>
          <p class="card_text d-none d-md-block" v-html="item.desc"></p>
          <p class="card_text d-block d-md-none" style="font-size:3.5vw !important;" v-html="item.desc"></p>
          <div class='position-absolute' style='right: 10px; bottom: 10px;'>
          <!-- <wp-swirel :type='item.img'></wp-swirel>
          {{item.img}} -->
          <img src="@/assets/images/wp-ar-one.png" v-if="item.img == 1" class="wp-ar-images" alt="" srcset="">
          <img src="@/assets/images/wp-ar-one-two.png" v-if="item.img == 2" class="wp-ar-images" alt="" srcset="">
          <img src="@/assets/images/wp-ar-two.png" v-if="item.img == 3" class="wp-ar-images" alt="" srcset="">
          <img src="@/assets/images/wp-ar-three.png" v-if="item.img == 4" class="wp-ar-images" alt="" srcset="">
          <img src="@/assets/images/wp-ar-four.png" v-if="item.img == 5" class="wp-ar-images" alt="" srcset="">
          <img src="@/assets/images/wp-ar-five.png" v-if="item.img == 6" class="wp-ar-images" alt="" srcset="">
          <img src="@/assets/images/wp-ar-six.png" v-if="item.img == 7" class="wp-ar-images" alt="" srcset="">
          <img src="@/assets/images/wp-ar-seven.png" v-if="item.img == 8" class="wp-ar-images" alt="" srcset="">
          <img src="@/assets/images/wp-ar-eight.png" v-if="item.img == 9" class="wp-ar-images" alt="" srcset="">

          </div>
      </div>
  </div>
</template>

<script>
import WpSwirel from '@/components/swirls/WpSwirel.vue'
export default {
    name: "WpInfoCard",
    components:{
        WpSwirel
    },
    props:['item']
}
</script>

<style>

</style>
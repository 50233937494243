<template>
  <div class='row p-0 m-0'>
      <div class="col-md-11 clLight ml-2">
          <div class="row">
              <div class="col-12 p-0 m-0"><h1 class="wp-title m-4 px-lg-4 float-right">Brochure</h1></div>
              <!-- <div class="col-12 d-lg-none"><h1 class="wp-title- m-4 px-lg-4 float-right">WHITE PAPER</h1></div> -->
              <div class="col-12 px-0">
                  <div class="border-bottom-gold y-line2"></div>
                  <WhitePaperComp />
                  
              </div>
          </div>
          
              
        </div>
        <div class="col-lg-1"></div>
        <div class="col-12 col-lg-11 px-0 m-0">
            <div class="container-fluid my-5 pl-0 mx-0 pr-lg-4">
              <h1 class="wp-title pl-4 p-0 mx-0 mt-0 mb-5 text-left">PRODUCT KEY FEATURES</h1>
                <div class="row px-0 mx-0">
                    <div class="col-xl-4 col-lg-6 col-md-6 col-12 p-1 pr-0" v-for='card in wpCards' :key='card.title'>
                        <wp-info-card :item='card'></wp-info-card>
                    </div>
                </div>
            </div>
        </div>
  </div> 
</template>

<script>
import WpInfoCard from "@/components/WpInfoCard.vue"
import WhitePaperComp from "@/components/WhitePaperComp.vue"
import {mapState} from "vuex"
export default {
    components:{
        WhitePaperComp,
        WpInfoCard
    },
    name: "WpTitleSection",
    computed:{
        ...mapState(['wpCards'])
    },
}
</script>

<style>

</style>